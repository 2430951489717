import { makeStyles } from '@material-ui/styles';

import { HeroText } from './components/HeroText/HeroText';
import { Categories } from './components/Categories/Categories';
import { RecipesGallery } from './components/RecipesGallery/RecipesGallery';
import { ImageOverText } from './components/ImageOverText/ImageOverText';

/** Styling Component */
const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const Recipes = () => {
    const classes = useStyles();

    const recipesImages = [
        '/img/glass.JPEG',
        '/img/glass2.JPEG',
        '/img/glass.JPEG',
        '/img/glass2.JPEG',
        '/img/glass.JPEG',
        '/img/glass2.JPEG',
        '/img/glass.JPEG',
        '/img/glass2.JPEG',
    ];
    return (
        <div className={classes.root}>
            <HeroText />

            <Categories />

            <RecipesGallery recipesImages={recipesImages} />

            <ImageOverText />
        </div>
    );
};
