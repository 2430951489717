import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    Button,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

const reorder = (list, startIndex, endIndex) => {
    const result = Object.values(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const ListItems = (props) => {
    const { t } = useTranslation();
    const { titleList, options, lg, md, sm, xs, onClickAdd, onClickDelete, list } = props;
    const classes = useStyles();

    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <Typography variant='body1' align='left' className={classes.titleList}>
                {titleList}
            </Typography>
            {options.map((item, index) => {
                const keys = Object.keys(item);

                return (
                    <div>
                        <List style={{ padding: 0 }}>
                            <ListItem ContainerComponent='li' className={classes.listItem}>
                                <ListItemText
                                    primary={item[keys[0]]}
                                    secondary={item[keys[1]]}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                    }}
                                    secondaryTypographyProps={{
                                        variant: 'body2',
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => onClickAdd(item, index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onClickDelete(index, list)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </div>
                );
            })}

            <Button className={classes.button} color='secondary' onClick={() => onClickAdd(null)}>
                <ListItem ContainerComponent='li'>
                    <ListItemIcon>
                        <AddCircleIcon className={classes.iconButton} />
                    </ListItemIcon>
                    <ListItemText primary={t('label27')} />
                </ListItem>
            </Button>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        border: `2px solid ${theme.palette.secondary.main}`,
        borderStyle: 'dashed',
        textTransform: 'capitalize',
        padding: 0,
    },
    iconButton: {
        color: theme.palette.secondary.main,
    },
    titleList: {
        fontWeight: '500',
        marginBottom: 20,
    },
    listItem: {
        backgroundColor: theme.palette.grayLight,
        marginBottom: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
    },
}));

ListItems.propTypes = {
    titleList: PropTypes.string,
    options: PropTypes.object,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
ListItems.defaultProps = {
    titleList: '',
    options: [''],
    lg: '4',
    md: '12',
    sm: '12',
    xs: '12',
};

export default ListItems;
