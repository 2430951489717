import React from 'react';
import PropTypes from 'prop-types';
import CustomBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const Backdrop = (props) => {
    const { isLoading } = props;

    const classes = useStyles();
    const CustomProgress = (props) => {
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant='determinate'
                    className={classes.bottom}
                    size={60}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant='indeterminate'
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={60}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    };
    return (
        <div>
            <CustomBackdrop className={classes.backdrop} open={isLoading}>
                <div className={classes.root}>
                    <CustomProgress />
                </div>
            </CustomBackdrop>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: theme.palette.secondary.main,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        overflowY: 'hidden',
    },
}));
Backdrop.propTypes = {
    isLoading: PropTypes.bool,
};
Backdrop.defaultProps = {
    isLoading: false,
};

export default Backdrop;
