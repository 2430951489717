import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { PickerLanguaje } from '../';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoSelector } from '../../Redux/selector';
import { tokenSelector } from '../../Redux/selector/';
import { logoutAction } from '../../Redux/actions/loginActions';
import { userInfoAction } from '../../Redux/actions/userAction';
import { useHistory } from 'react-router';

function ScrollTop(props) {
    const dispatch = useDispatch();
    const { children, window } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role='presentation' className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}
ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const drawerWidth = 240;
const Navbar = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const token = useSelector((state) => tokenSelector(state));
    let menuList = [
        {
            text: 'Balance',
            onclick: () => handleRedirectMenu('/balance'),
        },
        {
            text: 'Calendar',
            onclick: () => handleRedirectMenu('/calendar'),
        },
        {
            text: 'History',
            onclick: () => handleRedirectMenu('/history'),
        },
        {
            text: 'Profile',
            onclick: () => handleRedirectMenu('/profile'),
        },
        {
            text: 'Log Out',
            onclick: () => handleLogout(),
        },
    ];

    const goToHomeHanlder = () => {
        history.push('/');
    };
    /*
    if (token) {
        dispatch(userInfoAction());
    }
    */
    //console.log("NAVBAR")
    const userInfo = useSelector((state) => userInfoSelector(state));
    const role = JSON.stringify(userInfo.type).toLowerCase().replaceAll('"', '');
    if (userInfo.type === 'MENTEE') {
        menuList = [
            {
                text: 'Calendar',
                onclick: () => handleRedirectMenu('/calendar'),
            },
            {
                text: 'Transactions',
                onclick: () => handleRedirectMenu('/transactions'),
            },
            {
                text: 'History',
                onclick: () => handleRedirectMenu('/history'),
            },
            {
                text: 'Profile',
                onclick: () => handleRedirectMenu('/profile'),
            },
            {
                text: 'Log Out',
                onclick: () => handleLogout(),
            },
        ];
    }

    const handleLogout = () => {
        dispatch(logoutAction());
        history.push('/');
    };

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const isMenuOpen = Boolean(anchorEl);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleRedirectMenu = (url) => {
        setAnchorEl(null);
        handleMobileMenuClose();
        history.push(url);
    };

    const menuLinks = [
        {
            name: 'Home',
            to: '/',
        },
        {
            name: 'About Us',
            to: '/about',
        },
        {
            name: 'Partners',
            to: '/partners',
        },
        {
            name: 'Recipes',
            to: '/recipes',
        },
        {
            name: 'Mixologists',
            to: '/mixologists',
        },
        {
            name: 'Shop',
            to: 'https://mixoloworld.myshopify.com/',
        },
        {
            name: 'Contact Us',
            to: '/contact-us',
        },
    ];

    const goToShopPageHandler = (name) => {
        if (name === 'Shop') window.location.href = 'https://mixoloworld.myshopify.com/';
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role='presentation'
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {menuLinks.map((link, index) => (
                    <ListItem
                        component={Link}
                        to={link.to}
                        onClick={goToShopPageHandler.bind(null, link.name)}
                        key={link.name}
                    >
                        <ListItemText primary={link.name} className={classes.colorWhite} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {menuList.map((list, index) => (
                <MenuItem key={index} onClick={list.onclick}>
                    {list.text}
                </MenuItem>
            ))}
        </Menu>
    );
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label='show 4 new mails' color='inherit'>
                    <Badge badgeContent={4} color='secondary'>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label='show 11 new notifications' color='inherit'>
                    <Badge badgeContent={11} color='secondary'>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='primary-search-account-menu'
                    aria-haspopup='true'
                    color='inherit'
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const renderSectionAccount = (
        <div className={classes.sectionAccount}>
            <IconButton aria-label='show 17 new notifications' color='inherit'>
                <Badge badgeContent={17} color='error'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Button onClick={handleProfileMenuOpen} style={{ margin: 5 }}>
                <Avatar src={userInfo?.profile_img || 'img/noPhoto.png'} />
                <div className={classes.containerTextAccount}>
                    <div className={classes.containerTitleAccount}>
                        <Typography
                            variant='subtitle1'
                            color='white'
                            className={classes.titleAccount}
                        >
                            {userInfo.first_name}
                        </Typography>
                        <ArrowDropDownIcon className={classes.iconAccount} />
                    </div>
                    <div className={classes.containerSubtitleAccount}>
                        <Typography
                            variant='caption'
                            color='secondary'
                            style={{ textTransform: 'capitalize' }}
                            className={classes.subtitleAccount}
                        >
                            {role}
                        </Typography>
                    </div>
                </div>
            </Button>
        </div>
    );

    const renderSectionLoginSignUp = (
        <div className={classes.sectionLoginSignUp}>
            <Button color='inherit' className={classes.buttonsLoginSignUp} href='/login'>
                {' '}
                {t('label1')}
            </Button>
            <Button
                variant='outlined'
                className={classes.buttonsLoginSignUp}
                color='secondary'
                href='/sign-up'
            >
                {t('label2')}
            </Button>
        </div>
    );

    return (
        <React.Fragment>
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Button
                        onClick={goToHomeHanlder}
                        disableRipple
                        className={classes.ButtonLogoHeader}
                    >
                        <img className={classes.logoHeader} src='/img/logo.png' alt='Logo' />
                    </Button>
                    {['left'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className={classes.sectionMenuMobile}>
                                <IconButton
                                    aria-label='show more'
                                    aria-haspopup='true'
                                    color='inherit'
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <SwipeableDrawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                onOpen={toggleDrawer(anchor, true)}
                            >
                                {list(anchor)}
                            </SwipeableDrawer>
                        </React.Fragment>
                    ))}

                    <div className={classes.sectionMenuDesktop}>
                        {menuLinks.map((item, i) => (
                            <Button
                                key={i}
                                color='inherit'
                                component={Link}
                                to={item.to}
                                onClick={goToShopPageHandler.bind(null, item.name)}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </div>

                    {token ? renderSectionAccount : renderSectionLoginSignUp}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {token ? renderMenu : null}
            <Toolbar id='back-to-top-anchor' style={{ padding: 0 }} />
            <ScrollTop {...props}>
                <Fab color='primary' size='small' aria-label='scroll back to top'>
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        padding: 0,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    ButtonLogoHeader: {
        borderRadius: 0,
        height: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logoHeader: {
        width: 140,
    },
    grow: {
        flexGrow: 1,
    },
    sectionMenuDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        marginLeft: 100,
        marginRight: 100,
    },
    sectionMenuMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    sectionAccount: {
        display: 'flex',
        alignItems: 'center',
    },
    sectionLoginSignUp: {
        display: 'flex',
        flexDirection: 'row',
    },
    colorWhite: {
        color: theme.palette.black,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    buttonsLoginSignUp: {
        margin: 5,
    },
    containerTextAccount: {
        marginLeft: 10,
    },
    containerTitleAccount: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleAccount: {
        marginBottom: -10,
        color: theme.palette.white,
        textTransform: 'capitalize',
    },
    containerSubtitleAccount: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    subtitleAccount: {
        textTransform: 'capitalize',
    },
    iconAccount: {
        color: theme.palette.white,
    },
}));

export default Navbar;
