import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomButton from '@material-ui/core/Button';

const ButtonCheck = (props) => {
    const { value, textValue, selected, className, onClick } = props;

    return (
        <CustomButton
            onClick={() => {
                onClick(value);
            }}
            variant={value !== selected ? 'outlined' : 'contained'}
            startIcon={value !== selected ? '' : <CheckCircleIcon />}
            color='secondary'
            className={className}
        >
            {textValue}
        </CustomButton>
    );
};
ButtonCheck.propTypes = {
    value: PropTypes.string,
    textValue: PropTypes.string,
    selected: PropTypes.bool,
    className: PropTypes.object,
    onClick: PropTypes.func,
};
ButtonCheck.defaultProps = {
    value: 'none',
    textValue: 'none',
    selected: false,
    className: [],
    onClick: () => console.log('onChange'),
};

export default ButtonCheck;
