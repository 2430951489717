import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const ImageCard = (props) => {
    const { title, subtitle, onClick, img, elevation, overlay } = props;
    const classes = useStyles();
    return (
        <Paper
            className={classes.root}
            elevation={elevation}
            style={{ backgroundImage: `url(${img})` }}
        >
            <Button
                className={classes.touchable}
                onClick={onClick}
                style={{
                    pointerEvents: !onClick ? 'none' : null,
                    backgroundColor: overlay,
                }}
            >
                <div>
                    <Typography variant='h6' className={classes.title}>
                        {title}
                    </Typography>
                    {subtitle ? (
                        <Typography variant='subtitle1' className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                    ) : null}
                </div>
            </Button>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 15,
    },
    touchable: {
        height: 300,
        width: '100%',
    },
    title: {
        lineHeight: 1,
        color: theme.palette.white,
    },
    subtitle: {
        lineHeight: 1.5,
        color: theme.palette.secondary.main,
    },
}));

ImageCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
    img: PropTypes.string,
    elevation: PropTypes.number,
    overlay: PropTypes.string,
};
ImageCard.defaultProps = {
    title: '',
    subtitle: '',
    img: '',
    elevation: 1,
    overlay: 'rgba(0,0,0,0.7)',
};

export default ImageCard;
