import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarSelector } from '../../Redux/selector';
import { snackbarAction } from '../../Redux/actions/utilityActions';

const SnackbarAlert = () => {
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => snackbarSelector(state));

    const closeSnackbar = () => {
        dispatch(
            snackbarAction({
                open: false,
                type: '',
                message: '',
            })
        );
    };
    return (
        <Snackbar
            open={snackbar.open}
            onClose={closeSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert
                severity={snackbar.type}
                elevation={6}
                variant='filled'
                onClose={closeSnackbar}
            >
                {snackbar.message}
            </MuiAlert>
        </Snackbar>
    );
};

export default SnackbarAlert;
