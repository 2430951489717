import React from 'react';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import CustomBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Theme from '../../utils/theme';

const Breadcrumbs = (props) => {
    const {
        history,
        location: { pathname },
    } = props;
    const classes = useStyles();
    const pathnames = pathname.split('/').filter((x) => x);

    return (
        <div className={classes.root} style={pathnames.length == 0 ? { display: 'none' } : null}>
            <CustomBreadcrumbs
                aria-label='breadcrumb'
                separator={<NavigateNextIcon fontSize='small' />}
            >
                <Chip
                    onClick={() => history.push('/')}
                    label='Home'
                    style={pathnames.length == 0 ? { display: 'none' } : null}
                    className={classes.routes}
                />
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;
                    const route = name.replace('-', ' ');

                    return isLast ? (
                        <Chip
                            component='a'
                            key={name}
                            label={route}
                            className={classes.currentRoute}
                        />
                    ) : (
                        <Chip
                            key={name}
                            onClick={() => history.push(routeTo)}
                            label={route}
                            className={classes.routes}
                        />
                    );
                })}
            </CustomBreadcrumbs>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderBottom: `1px solid ${Theme.palette.lightGray} `,
        padding: 20,
    },
    currentRoute: {
        display: 'flex',
        textTransform: 'capitalize',
        color: theme.palette.white,
        backgroundColor: theme.palette.secondary.main,
        fontWeight: '500',
        height: theme.spacing(3),
        '&:hover, &:focus': {
            backgroundColor: theme.palette.secondary.main[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.secondary.main, 0.12),
        },
    },
    routes: {
        display: 'flex',
        textTransform: 'capitalize',
        backgroundColor: theme.palette.primary.main[100],
        fontWeight: '400',
        height: theme.spacing(3),
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}));

export default withRouter(Breadcrumbs);
