import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

/** Styling Components */
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1200px',
        margin: '50px auto',
    },
    gallerImage: {
        width: '290px',
        height: '290px',
        overflow: 'hidden',
        objectFit: 'cover',

        [theme.breakpoints.down('sm')]: {
            width: '100%%',
        },
    },
}));

export const RecipesGallery = ({ recipesImages }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container spacing={1} justifyContent='center'>
                {recipesImages.map((img) => (
                    <Grid item sm={3} key={img}>
                        <img className={classes.gallerImage} src={img} alt='Mixoloworld' />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

RecipesGallery.protTypes = {
    recipesImages: PropTypes.array.isRequired,
};
