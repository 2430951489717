import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

/** Styling Component */
const useStyles = makeStyles((theme) => ({
    heroText: {
        maxWidth: '800px',
        margin: '80px auto',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            margin: '50px 15px',
        },
    },
    heroTitle: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    heroButton: {
        marginTop: '50px',
    },
}));

export const HeroText = () => {
    const classes = useStyles();

    const content = [
        `If you are looking for new experiences, you're in the right place. Can you imagine,
        having recipes from experienced artists, and being able to make them at home? Well,
        dream no more, here you will find fun and exciting blends so you can bring a cool
        trick to your next cool gathering.`,
        `Hit the button and buy the perfect kit with all the ingredients you'll need to make
        your drink of choice with your friends!`,
        `Don't forgot to tag us @mixoloworld so we can see your creations! Be a part of our
        community, be part of the new way to learn about mixology and it's culture, while
        having mad fun.`,
        `Remember you can register and start your own Mixoloworld recipe book!`,
    ];
    return (
        <div className={classes.heroText}>
            <Typography className={classes.heroTitle} variant='h2' gutterBottom>
                Recipes
            </Typography>

            {content.map((text) => (
                <Typography
                    key={Math.random() * 999}
                    className={classes.marginTop}
                    variant='subtitle1'
                    gutterBottom
                >
                    {text}
                </Typography>
            ))}

            <Button className={classes.heroButton} variant='contained' color='primary'>
                Register now
            </Button>
        </div>
    );
};
