import { makeStyles, Button, Box } from '@material-ui/core';

/** Styling Component */
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        margin: 0,
        padding: 0,
    },
    image: {
        width: '100%',
        height: '600px',
        objectFit: 'cover',
    },
    button: {
        position: 'absolute',
        width: '220px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '19px',
        textDecoration: 'underline',
    },
}));

export const ImageOverText = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img className={classes.image} src='/img/manBar.jpg' alt='Mixoloworld' />
            <Button className={classes.button} variant='contained' color='primary'>
                Register now
            </Button>
        </Box>
    );
};
