import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const ToggleGroup = (props) => {
    const { value, onChange, options } = props;
    const classes = useStyles();
    return (
        <ToggleButtonGroup value={value} exclusive onChange={onChange} className={classes.root}>
            {options.map((value) => (
                <ToggleButton key={value} value={value}>
                    {value}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        margin: 20,
    },
}));

ToggleGroup.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
};
ToggleGroup.defaultProps = {
    value: '',
};

export default ToggleGroup;
