import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GridItem from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import { TextInput } from '../';
import { useTranslation } from 'react-i18next';
const PickerChip = (props) => {
    const { t } = useTranslation();
    const { xs, placeholder, interestAvailables, val, setVal } = props;
    const classes = useStyles();
    const itemsSelected = val.map((option, index) => {
        const label = option.description || option;
        return (
            <Chip
                color='secondary'
                key={label}
                label={label}
                deleteIcon={<CancelIcon />}
                onDelete={() => {
                    setVal(val.filter((entry) => entry !== option));
                }}
                className={classes.itemSelected}
            />
        );
    });

    return (
        <GridItem item xs={xs}>
            <Autocomplete
                multiple
                id='tags-standard'
                freeSolo
                fullWidth
                filterSelectedOptions
                options={interestAvailables}
                onChange={(e, newValue) => setVal(newValue)}
                getOptionLabel={(option) => option.description}
                renderTags={() => {}}
                value={val}
                renderInput={(selectChip) => (
                    <TextInput {...selectChip} placeholder={placeholder} />
                )}
            />
            <div className={classes.containerItems}>
                {itemsSelected.length !== 0 ? (
                    itemsSelected
                ) : (
                    <Typography variant='body1'>{t('label20')}</Typography>
                )}
            </div>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    containerItems: {
        padding: 20,
        border: `1px solid ${theme.palette.gray} `,
        borderRadius: 5,
        marginTop: 20,
    },
    itemSelected: {
        margin: 5,
    },
}));

PickerChip.propTypes = {
    placeholder: PropTypes.string,
    xs: PropTypes.string,
};
PickerChip.defaultProps = {
    placeholder: '',
    xs: '12',
};

export default PickerChip;
