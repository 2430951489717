import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CustomDivider from '@material-ui/core/Divider';
import GridItem from '@material-ui/core/Grid';

const Divider = (props) => {
    const classes = useStyles();
    const { xs } = props;
    return (
        <GridItem item xs={xs}>
            <CustomDivider className={classes.divider} />
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: 10,
    },
}));

Divider.propTypes = {
    xs: PropTypes.string,
};
Divider.defaultProps = {
    xs: '12',
};

export default Divider;
