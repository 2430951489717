import { makeStyles, Button, ButtonGroup } from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

/** Styling Component */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            margin: 'auto 15px',
        },
    },
    groupButtons: {
        border: `1px solid ${theme.palette.black}`,

        [theme.breakpoints.down('sm')]: {
            border: `0px solid transparent`,
            margin: 'auto',
            textAlign: 'center',
        },
    },
    button: {
        fontSize: '18px',
        borderRight: `1px solid ${theme.palette.black}`,
        borderRadius: '0',
        fontWeight: 'bold',

        '&:last-child': {
            borderRight: `0px solid transparent`,
        },

        [theme.breakpoints.down('sm')]: {
            border: `1px solid ${theme.palette.black}`,
        },
    },

    filter: {
        background: theme.palette.black,
        color: theme.palette.white,
    },
}));

export const Categories = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.groupButtons}>
                {[...Array(5)].map((category) => (
                    <Button
                        key={Math.random() * 999}
                        className={classes.button}
                        endIcon={<ArrowDropDownIcon />}
                    >
                        Category
                    </Button>
                ))}
                <Button className={clsx(classes.button, classes.filter)}>Filter</Button>
            </div>
        </div>
    );
};
