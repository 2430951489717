import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#151515',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ffffff',
            dark: '#2b8845',
            contrastText: '#fff',
        },
        error: {
            main: '#e60000',
        },
        white: '#fff',
        black: '#222',
        grayLight: '#f2f2f2',
        gray: '#cccccc',
        grayDark: '#bfbfbf',
        lightGray: '#f2f2f2',
        lightestGray: '#F9F9F9',
        colorLogo1: '#262626',
        colorLogo2: '#333333',
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                fontSize: 16,
            },
            input: {
                height: 40,
                boxSizing: 'border-box',
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 16,
            },
        },
    },
});

theme.overrides.MuiDropzoneArea = {
    root: {
        minHeight: '0px',
        padding: 30,
        marginTop: 15,
        marginBottom: 15,
        borderColor: theme.palette.secondary.main,
    },
    text: {
        color: theme.palette.grayDark,

        fontSize: '1.2rem',
        '@media (min-width:600px)': {
            fontSize: '1.3rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.4rem',
        },
    },
    icon: {
        color: theme.palette.secondary.main,
    },
};

theme.typography.h2 = {
    fontSize: '2.4rem',
    '@media (min-width:600px)': {
        fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.8rem',
    },
};

theme.typography.h3 = {
    fontSize: '2.1rem',
    '@media (min-width:600px)': {
        fontSize: '2.2rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
    },
};

theme.typography.h4 = {
    fontSize: '1.8rem',
    '@media (min-width:600px)': {
        fontSize: '1.9rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.2rem',
    },
};

theme.typography.h5 = {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
        fontSize: '1.6rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.9rem',
    },
};

theme.typography.h6 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.2rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.4rem',
    },
};

theme.typography.body1 = {
    fontSize: '0.9rem',
    '@media (min-width:600px)': {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
    },
};

export default theme;
