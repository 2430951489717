import { put, call, select, takeLatest } from 'redux-saga/effects';

import { snackbarAction } from '../actions/utilityActions';
import { USER_INFO_START, USER_INFO_COMPLETE } from '../actionType';
import { apiCall } from '../../utils/apiCall';
import { GET_USER_INFO } from '../../utils/urls';
import { tokenSelector } from '../selector';
//import { setLocalStorage, EMAIL } from '../../utils/localStorage';
import {
    setLocalStorage,
    setLocalStorageEmail,
    setLocalStorageFirstName,
    setLocalStorageId,
    setLocalStorageLastName,
    setLocalStorageType,
    setLocalStorageImgProfile,
    REFRESH_TOKEN,
    GET_EMAIL,
    GET_FIRST_NAME,
    GET_LAST_NAME,
    GET_TYPE,
    GET_ID,
    GET_IMG_PROFILE,
} from '../../utils/localStorage';
function* getUser() {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, GET_USER_INFO, null, token, 'GET');
        yield put({ type: USER_INFO_COMPLETE, payload: result.data });
        yield setLocalStorageEmail(result.data.email, GET_EMAIL);
        yield setLocalStorageFirstName(result.data.first_name, GET_FIRST_NAME);
        yield setLocalStorageLastName(result.data.last_name, GET_LAST_NAME);
        yield setLocalStorageType(result.data.type, GET_TYPE);
        yield setLocalStorageId(result.data.id, GET_ID);
        yield setLocalStorageImgProfile(result.data.profile_img, GET_IMG_PROFILE);
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error',
            })
        );
    }
}

export default function* watchUser() {
    yield takeLatest(USER_INFO_START, getUser);
}
