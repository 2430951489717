import { put } from 'redux-saga/effects';

import { LOGIN_COMPLETE, USER_INFO_COMPLETE } from '../actionType';
import {
    getLocalStorage,
    REFRESH_TOKEN,
    getLocalStorageEmail,
    getLocalStorageType,
    getLocalStorageFirstName,
    getLocalStorageLastName,
    getLocalStorageId,
    getLocalStorageImgProfile,
} from '../../utils/localStorage';

export default function* storeToken() {
    const token = yield getLocalStorage();
    const refresh = yield getLocalStorage(REFRESH_TOKEN);

    //profile
    const email = yield getLocalStorageEmail();
    const type = yield getLocalStorageType();
    const firstName = yield getLocalStorageFirstName();
    const lastName = yield getLocalStorageLastName();
    const id = yield getLocalStorageId();
    const img_profile = yield getLocalStorageImgProfile();

    const payload = {
        access_token: token,
        refresh_token: refresh,
    };

    const payloadProfile = {
        email: email,
        type: type,
        first_name: firstName,
        last_name: lastName,
        id: id,
        profile_img: img_profile,
    };

    yield put({ type: LOGIN_COMPLETE, payload });
    yield put({ type: USER_INFO_COMPLETE, payload: payloadProfile });
}
