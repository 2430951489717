import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const NotificationAlert = (props) => {
    const { show, hide, type } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar
                open={show}
                autoHideDuration={2000}
                onClose={hide}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={hide} severity={type}>
                    This is a success message!
                </Alert>
            </Snackbar>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default NotificationAlert;
