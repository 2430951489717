import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import { useTranslation } from 'react-i18next';
import GridItem from '@material-ui/core/Grid';

const InfoCard = (props) => {
    const { title, description, photo, rating, onClick, lg, md, sm, xs } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <Card className={classes.root}>
                <CardMedia className={classes.photo} image={photo} />
                <CardContent>
                    <Typography gutterBottom variant='h6'>
                        {title}
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {description}
                    </Typography>
                    <Typography variant='subtitle1' className={classes.titlerating}>
                        {t('label45')}
                    </Typography>
                    <Rating className={classes.rating} value={rating} readOnly />
                    <Button color='secondary' variant='contained' onClick={onClick} fullWidth>
                        {t('label44')}
                    </Button>
                </CardContent>
            </Card>
        </GridItem>
    );
};

const useStyles = makeStyles({
    root: {
        maxWidth: '100%',
        margin: 10,
    },
    photo: {
        height: 200,
    },
    titlerating: {
        fontWeight: 'bold',
        marginTop: 10,
    },
    rating: {
        marginBottom: 20,
    },
});

InfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    photo: PropTypes.string,
    rating: PropTypes.number,
    onClick: PropTypes.func,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
InfoCard.defaultProps = {
    title: '',
    subtitle: '',
    photo: 'img/noPhoto.png',
    rating: 0,
    lg: '3',
    md: '4',
    sm: '6',
    xs: '12',
};

export default InfoCard;
