import axios from 'axios';
import { URL_API } from '../utils/urls';
import { getLocalStorage, GET_TOKEN, setLocalStorage } from '../utils/localStorage';

export const authCall = (url, data, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const apiCall = (url, data, token, method, headers, responseType) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
            ...headers,
        },
        ...responseType,
    });

export const multipleCall = (url, data, token, method) => {
    return axios
        .all(
            data.map((link) =>
                axios.get(`${URL_API + url}/${link}`, {
                    headers: {
                        Authorization: `JWT ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
            )
        )
        .then(axios.spread((...res) => res.map((data) => data.data)));
};
