import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import GridItem from '@material-ui/core/Grid';
const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

const SelectInput = (props) => {
    const { options, placeholder, lg, md, sm, xs, setCustomObjectData, ...others } = props;
    const classes = useStyles();

    const handleOnChange = (e) => {
        if (setCustomObjectData) {
            setCustomObjectData((data) => ({
                ...data,
                [e.target.name]: e.target.value,
            }));
        }
    };
    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <TextField
                fullWidth
                select
                label={placeholder}
                SelectProps={{
                    native: true,
                }}
                variant='outlined'
                size='small'
                onChange={handleOnChange}
                {...others}
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </TextField>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
    },
}));

SelectInput.propTypes = {
    options: PropTypes.object,
    placeholder: PropTypes.string,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
SelectInput.defaultProps = {
    options: [],
    placeholder: '',
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
};

export default SelectInput;
