import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const PickerLanguaje = () => {
    const { i18n } = useTranslation();
    const theme = useTheme();
    document.body.dir = i18n.dir();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.body.dir = i18n.dir();
        theme.direction = i18n.dir();
    };

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const getCurrentLanguaje = localStorage.getItem('languajeSelected');
        if (getCurrentLanguaje) {
            setSelectedIndex(JSON.parse(getCurrentLanguaje));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('languajeSelected', JSON.stringify(selectedIndex));
    });

    const options = [
        {
            languaje: 'EN',
            flag: 'en',
        },
        {
            languaje: 'ES',
            flag: 'es',
        },
    ];

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        changeLanguage(options[index].flag);
        setSelectedIndex(index, null);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button className={classes.root} onClick={handleClickListItem}>
                <img
                    className={classes.flag}
                    src={`/img/flags/${options[selectedIndex].flag}.png`}
                />
                <Typography variant='button' className={classes.currentLanguaje}>
                    {options[selectedIndex].languaje}
                </Typography>
                <ArrowDropDownIcon className={classes.icon} />
            </Button>
            <Menu id='lock-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        <img className={classes.flag} src={`/img/flags/${option.flag}.png`} />
                        <Typography variant='button' className={classes.listLanguaje}>
                            {option.languaje}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10%',
        padding: 10,
    },
    flag: {
        height: 18,
        marginRight: 10,
    },
    currentLanguaje: {
        color: theme.palette.white,
        fontWeight: '600',
    },
    listLanguaje: {
        color: theme.palette.primary.main,
        fontWeight: '400',
    },
    icon: {
        color: theme.palette.white,
    },
}));

export default PickerLanguaje;
