import { get } from 'lodash';

/** Utility Reducer */
export const snackbarSelector = (state) => get(state, 'utilityReducer.snackBar');
export const isLoadingSelector = (state) => get(state, 'utilityReducer.isLoading');
export const is_successSelector = (state) => get(state, 'utilityReducer.is_success');
export const isRequestOkSelector = (state) => get(state, 'utilityReducer.isRequestOk');

export const isModalCreateUserSelector = (state) => get(state, 'utilityReducer.isModalCreateUser');
export const isModalEditUserSelector = (state) => get(state, 'utilityReducer.isModalEditUser');

/** Login */
export const tokenSelector = (state) => get(state, 'loginReducer.access_token');
export const getEmailSelector = (state) => get(state, 'loginReducer.email');

/** User Info */
export const userInfoSelector = (state) => get(state, 'userReducer');

/** Home */
export const listMentorsSelector = (state) => get(state, 'listMentorsReducer.items');
export const idFilesMentorsSelector = (state) => get(state, 'idFilesMentorsReducer.idsFiles');
