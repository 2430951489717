import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import GridItem from '@material-ui/core/Grid';

const DateInput = (props) => {
    const { placeholder, lg, md, sm, xs, ...others } = props;

    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <DatePicker
                label={placeholder}
                inputVariant='outlined'
                size='small'
                openTo='year'
                format='dd/MM/yyyy'
                views={['year', 'month', 'date']}
                fullWidth
                disableFuture
                {...others}
            />
        </GridItem>
    );
};

DateInput.propTypes = {
    placeholder: PropTypes.string,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
DateInput.defaultProps = {
    placeholder: '',
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
};
export default DateInput;
