import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Theme from '../../utils/theme';

const View = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.banner}>
                <div className={classes.content}>
                    <Typography variant='h2' color='primary' style={{ color: Theme.palette.white }}>
                        ABOUT US
                    </Typography>
                    <Typography variant='body1' gutterBottom style={{ color: Theme.palette.white }}>
                        Few things can be compared to the feeling of being passionate about
                        something, and being able to share it with the world is a privilege. You can
                        now be a part of the ultimate mixology experience and get immersed in this
                        whole new world! <br />
                        <br />
                        At Mixoloworld we are driven by the possibility to connect people with
                        talent, and hungry brands with audacity to take the market. More so than
                        that, Mixoloworld is where you can share your mixology passion with others
                        and build a community that will keep growing into something bigger.
                    </Typography>
                </div>
            </div>
            <div>
                <div className={classes.content}>
                    <Typography variant='h5' color='primary' style={{ textAlign: 'left' }}>
                        We are the bridge between talent and business.
                        <br />
                        <br />
                    </Typography>
                    <Typography variant='body1' gutterBottom style={{ textAlign: 'left' }}>
                        Introducing an innovative platform that will match the right talent with the
                        proper spirit. Whether you are a liquor brand looking for a massive and
                        effective way to impact your audience, or you are an experienced bartender
                        looking for a way to fulfill your passion while having exposure and doing
                        business, Mixoloworld is the place to meet. <br />
                        <br />
                        Our mixologists are creative, skilled, and talented connected through
                        Mixoloworld and our concept, with alcoholic brands looking to make a buzz in
                        the market. The key is to create fun, diverse, and innovative drinks that
                        will engage the audience with the brands in a very unique way, every time.{' '}
                        <br />
                        <br />
                        We take advantage of the world that we live in and use social media
                        platforms and our website to create some noise and acquire recognition for
                        the brands and the mixologists that are part of this family. We generate 24
                        hour campaigns, it’s organized and fun. The mixology crew will be creating
                        special unique drinks with a specific liquor brand, and then at the same
                        time they will be uploading these stories showcasing the new cocktail and
                        liquor. They’ll be tagging the brand, Mixoloworld, and adding their
                        geo-location. <br />
                        <br />
                        You can grow inside our community of mixologists and gain prestige and
                        recognition for your abilities and passion. <br />
                        <br />
                        If you are curious and enthusiastic about the experience that Mixoloworld
                        brings to the table, register now on our web and start your journey into our
                        fascinating world of mixologists, and even start your online recipe book!{' '}
                        <br />
                        <br />
                        This is your chance to show your passion and connect with awesome brands and
                        people to create something out of the ordinary. <br />
                        <br />
                        Be a part of our inclusive community, powered by talented mixologists and
                        powerful brands wanting to team up and create inclusive and innovative
                        campaigns to gain exposure and a team representing them. <br />
                        <br />
                    </Typography>

                    <Button variant='contained' color='primary' className={classes.buttonContact}>
                        JOIN NOW
                    </Button>
                </div>
            </div>
        </div>
    );
};

View.propTypes = {
    recipes: PropTypes.array.isRequired,
    gallery: PropTypes.array.isRequired,
    testimonials: PropTypes.object,
    t: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    banner: {
        display: 'flex',
        backgroundImage: `url("/img/backgroundAbout.png")`,
        paddingTop: 100,
        paddingBottom: 100,
    },
    section2: {
        backgroundColor: theme.palette.lightestGray,
    },
    section4: {
        backgroundImage: `url("/img/backgroundTestimonials.png")`,
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
        marginLeft: 260,
        marginRight: 260,
        paddingTop: 60,
        paddingBottom: 60,
        width: 'fill-available',

        [theme.breakpoints.down('sm')]: {
            margin: '50px 15px',
            padding: 0,
        },
    },

    subtitle: {
        fontWeight: '300',
        width: '65%',
        margin: 'auto',
        marginTop: 15,
        marginBottom: 20,
    },
    buttonBanner: {
        margin: 10,
        fontWeight: '400',
        fontSize: 25,
    },
    buttonContact: {
        margin: 10,
        fontWeight: '400',
    },
    title: {
        fontWeight: 'bold',
    },
}));

export default View;
