import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import GridItem from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Grid } from '../';

const ListInfo = (props) => {
    const classes = useStyles();

    const { description1, description2, title, subtitle, description3 } = props;
    return (
        <div className={classes.root}>
            <Grid>
                <GridItem item lg='2' md='3' sm='6' xs='12'>
                    <Typography variant='body2'>{description1}</Typography>
                    <Typography variant='body2'>{description2}</Typography>
                </GridItem>
                <GridItem item lg='10' md='9' sm='6' xs='12'>
                    <Typography variant='body1' className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant='body2'>{subtitle}</Typography>
                </GridItem>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        marginBottom: 10,
    },
    title: {
        fontWeight: '500',
    },
}));

ListInfo.propTypes = {
    description1: PropTypes.string,
    description2: PropTypes.string,
    description3: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
ListInfo.defaultProps = {
    description1: '--',
    description2: '--',
    description3: '--',
    title: '--',
    subtitle: '--',
};

export default ListInfo;
