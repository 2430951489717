const isBrowser = window !== 'undefined';

export const setLocalStorage = (value, name = GET_TOKEN) =>
    isBrowser && localStorage.setItem(name, value);

//TOKEN
export const getLocalStorage = (name = GET_TOKEN) => isBrowser && localStorage.getItem(name);
export const rmLocalStorage = (name = GET_TOKEN) => isBrowser && localStorage.removeItem(name);
export const GET_TOKEN = '_access_token';
export const REFRESH_TOKEN = '_refresh_token';

//email
export const setLocalStorageEmail = (value, name = GET_EMAIL) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageEmail = (name = GET_EMAIL) => isBrowser && localStorage.getItem(name);
export const rmLocalStorageEmail = (name = GET_EMAIL) => isBrowser && localStorage.removeItem(name);
export const GET_EMAIL = '_email';

//first_name
export const setLocalStorageFirstName = (value, name = GET_FIRST_NAME) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageFirstName = (name = GET_FIRST_NAME) =>
    isBrowser && localStorage.getItem(name);
export const rmLocalStorageFirstName = (name = GET_FIRST_NAME) =>
    isBrowser && localStorage.removeItem(name);
export const GET_FIRST_NAME = '_first_name';

//last_name
export const setLocalStorageLastName = (value, name = GET_LAST_NAME) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageLastName = (name = GET_LAST_NAME) =>
    isBrowser && localStorage.getItem(name);
export const rmLocalStorageLastName = (name = GET_LAST_NAME) =>
    isBrowser && localStorage.removeItem(name);
export const GET_LAST_NAME = '_last_name';

//type
export const setLocalStorageType = (value, name = GET_TYPE) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageType = (name = GET_TYPE) => isBrowser && localStorage.getItem(name);
export const rmLocalStorageType = (name = GET_TYPE) => isBrowser && localStorage.removeItem(name);
export const GET_TYPE = '_type';

//id
export const setLocalStorageId = (value, name = GET_ID) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageId = (name = GET_ID) => isBrowser && localStorage.getItem(name);
export const rmLocalStorageId = (name = GET_ID) => isBrowser && localStorage.removeItem(name);
export const GET_ID = '_id';

//img_profile
export const setLocalStorageImgProfile = (value, name = GET_IMG_PROFILE) =>
    isBrowser && localStorage.setItem(name, value);
export const getLocalStorageImgProfile = (name = GET_IMG_PROFILE) =>
    isBrowser && localStorage.getItem(name);
export const rmLocalStorageImgProfile = (name = GET_IMG_PROFILE) =>
    isBrowser && localStorage.removeItem(name);
export const GET_IMG_PROFILE = '_img_profile';
