import { get } from 'lodash';

import { USER_INFO_COMPLETE } from '../actionType';

const initialState = {
    education: null,
    email: null,
    experience: null,
    first_name: null,
    id: null,
    interest: null,
    language: null,
    last_name: null,
    mentoring_area: null,
    phone_number: null,
    type: null,
    full_name: null,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO_COMPLETE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
