import { get } from 'lodash';

import { LIST_MENTORS_COMPLETE, ID_FILES_MENTORS_COMPLETE } from '../actionType';

const initialState = {
    items: [],
};

const initialStateidFilesMentors = {
    idsFiles: null,
};

export const listMentorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_MENTORS_COMPLETE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const idFilesMentorsReducer = (state = initialStateidFilesMentors, action) => {
    switch (action.type) {
        case ID_FILES_MENTORS_COMPLETE:
            return {
                ...state,
                idsFiles: action.payload,
            };
        default:
            return state;
    }
};
