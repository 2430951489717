import { get } from 'lodash';
import { LOGIN_COMPLETE, LOGOUT_COMPLETE } from '../actionType/';

const initialState = {
    access_token: null,
    refresh_token: null,
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_COMPLETE:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT_COMPLETE:
            return {
                ...state,
                access_token: null,
                refresh_token: null,
            };
        default:
            return state;
    }
};
