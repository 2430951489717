import { put, call, select, takeLatest } from 'redux-saga/effects';

import { snackbarAction } from '../actions/utilityActions';
import {
    LIST_MENTORS_START,
    LIST_MENTORS_COMPLETE,
    ID_FILES_MENTORS_START,
    ID_FILES_MENTORS_COMPLETE,
} from '../actionType';
import { authCall, apiCall } from '../../utils/apiCall';
import { USER_FILTERS, WELCOME_VIDEO_BY_EMAIL, URL_FILE_BY_KEYNAME } from '../../utils/urls';
import { tokenSelector } from '../selector';
//import { setLocalStorage, EMAIL } from '../../utils/localStorage';
import { isLoadingAction } from '../actions/utilityActions';

function* getListUser({ pageNumber, size, email, _id, name, category }) {
    const payload = {
        pageNumber,
        size,
        email,
        _id,
        name,
        category,
    };
    try {
        //const token = yield select(tokenSelector);
        const result = yield call(authCall, USER_FILTERS, payload, 'POST');
        //yield setLocalStorage(result.data.email, EMAIL);
        yield put({ type: LIST_MENTORS_COMPLETE, payload: result.data });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* getIdFiles({ email }) {
    yield put({ type: ID_FILES_MENTORS_COMPLETE, payload: null });
    try {
        const resultIdFiles = yield call(
            apiCall,
            WELCOME_VIDEO_BY_EMAIL + '/' + email,
            null,
            null,
            'GET'
        );
        const arrayIdFiles = resultIdFiles.data;
        if (arrayIdFiles.length > 0) {
            const resultUrlFiles = yield call(
                apiCall,
                URL_FILE_BY_KEYNAME + '/' + arrayIdFiles[0],
                null,
                null,
                'GET'
            );
            const arrayUrlFiles = resultUrlFiles.data;
            yield put({ type: ID_FILES_MENTORS_COMPLETE, payload: arrayUrlFiles });
            //console.log("RESULTADO_PRUEBA" + resultUrlFiles)
        } else {
            yield put({ type: ID_FILES_MENTORS_COMPLETE, payload: null });
        }
    } catch (error) {
        yield put({ type: ID_FILES_MENTORS_COMPLETE, payload: null });
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* watchListMentors() {
    yield takeLatest(LIST_MENTORS_START, getListUser);
    yield takeLatest(ID_FILES_MENTORS_START, getIdFiles);
}
