export const URL_API = 'http://api.mentoringline.com/';
export const AUTH = 'auth';
export const USERLIST = 'user';
export const USERS = 'users';
export const FORGOT_PASS = 'forgot_password';
export const GET_USER_INFO = 'protected';
export const RECOVERY_PASS = 'recovery_change_password';
export const USER_FILTERS = 'user_filters';
export const WELCOME_VIDEO_BY_EMAIL = 'welcome_video_by_email';
export const URL_FILE_BY_KEYNAME = 'url_file_by_keyname';
export const USER_MENTOR_WELCOME_VIDEO = 'user_mentor_welcome_video';
export const USER_MENTOR_EXPERIENCE = 'user_mentor_experience';
export const USER_MENTOR_EDUCATION = 'user_mentor_education';
