import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomDrawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import GridItem from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EventIcon from '@material-ui/icons/Event';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory } from 'react-router';
import { Navbar, Breadcrumbs } from '../';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../Redux/selector/';
import { userInfoSelector } from '../../Redux/selector';
const drawerWidth = 240;
const Drawer = (props) => {
    let location = useLocation();
    //console.log(location.pathname);
    const token = useSelector((state) => tokenSelector(state));
    const userInfo = useSelector((state) => userInfoSelector(state));
    const classes = useStyles();
    const { children } = props;
    const history = useHistory();
    const theme = useTheme();
    let drawerList = [];
    const [open, setOpen] = React.useState(true);
    if (userInfo.type === 'MENTEE') {
        drawerList = [
            {
                text: 'Calendar',
                onclick: () => history.push('/calendar'),
                icon: <EventIcon />,
            },
            {
                text: 'Transactions',
                onclick: () => history.push('/transactions'),
                icon: <AccountBalanceIcon />,
            },
            {
                text: 'History',
                onclick: () => history.push('/history'),
                icon: <AssignmentIcon />,
            },
            {
                text: 'Profile',
                onclick: () => history.push('/profile'),
                icon: <PersonIcon />,
            },
        ];
    } else {
        drawerList = [
            {
                text: 'Balance',
                onclick: () => history.push('/balance'),
                icon: <AccountBalanceWalletIcon />,
            },
            {
                text: 'Calendar',
                onclick: () => history.push('/calendar'),
                icon: <EventIcon />,
            },
            {
                text: 'History',
                onclick: () => history.push('/history'),
                icon: <AssignmentIcon />,
            },
            {
                text: 'Profile',
                onclick: () => history.push('/profile'),
                icon: <PersonIcon />,
            },
        ];
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    if (token) {
        if (
            location.pathname === '/balance' ||
            location.pathname === '/calendar' ||
            location.pathname === '/transactions' ||
            location.pathname === '/history' ||
            location.pathname === '/profile'
        ) {
            return (
                <div className={classes.root}>
                    <CssBaseline />
                    <Navbar />
                    <CustomDrawer
                        variant='permanent'
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <ChevronLeftIcon />
                                )}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            {drawerList.map((list, index) => (
                                <ListItem button key={list} onClick={list.onclick}>
                                    <ListItemIcon>{list.icon}</ListItemIcon>
                                    <ListItemText primary={list.text} />
                                </ListItem>
                            ))}
                        </List>
                        <GridItem item xs='12' />

                        <Divider />
                        <List>
                            <ListItem button onClick={open ? handleDrawerClose : handleDrawerOpen}>
                                <ListItemIcon>
                                    {open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                </ListItemIcon>
                                <ListItemText primary={open ? 'Close' : 'Open'} />
                            </ListItem>
                        </List>
                    </CustomDrawer>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {children}
                    </main>
                </div>
            );
        }
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Navbar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
}));

Drawer.defaultProps = {
    xs: '12',
};

export default Drawer;
