import { all } from 'redux-saga/effects';

import authUser from './loginSaga';
import userInfo from './userSaga';
import localToken from './localSaga';
import registerUser from './signUpSaga';
import sendTokenForgotPassword from './sendPasswordTokenSagas';
import watchUser from './userSaga';
import sendRecoveryPassword from './sendRecoveryPasswordSagas';
import watchListMentors from './mentorsSagas';

export default function* rootSaga() {
    yield all([
        localToken(),
        authUser(),
        userInfo(),
        registerUser(),
        sendTokenForgotPassword(),
        watchUser(),
        sendRecoveryPassword(),
        watchListMentors(),
    ]);
}
