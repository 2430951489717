import { put, call, takeLatest, select } from 'redux-saga/effects';
import { REGISTER_START, REGISTER_COMPLETE } from '../actionType';
import { authCall, apiCall } from '../../utils/apiCall';
import {
    USERLIST,
    USER_MENTOR_WELCOME_VIDEO,
    USER_MENTOR_EXPERIENCE,
    USER_MENTOR_EDUCATION,
} from '../../utils/urls';
import { isLoadingAction, snackbarAction, isRequestOkAction } from '../actions/utilityActions';
import { URL_API } from '../../utils/urls';
import axios from 'axios';

export function* register({ payload }) {
    try {
        let data = '';
        if (payload.type === 'MENTOR') {
            const { videoWelcome, education, experience, ...other } = payload;
            data = yield call(authCall, USERLIST, other, 'POST');

            //videoWelcome
            if (payload.videoWelcome[0]) {
                const formData = new FormData();
                formData.append('welcome_video', payload.videoWelcome[0]);
                formData.append('email', payload.videoWelcome.email);
                const responseWelcomVideo = yield call(
                    apiCall,
                    USER_MENTOR_WELCOME_VIDEO,
                    formData,
                    null,
                    'POST',
                    { 'Content-Type': 'application/x-www-form-urlencoded' }
                );
                //console.log(responseWelcomVideo)
            }
            //experience

            for (let i = 0; i < payload.experience.length; i++) {
                const formData = new FormData();
                formData.append('charge', payload.experience[i].charge);
                formData.append('company', payload.experience[i].company);
                formData.append('city', payload.experience[i].city);
                formData.append('start_date', payload.experience[i].start_date);
                formData.append('end_date', payload.experience[i].end_date);
                formData.append('description', payload.experience[i].description);
                for (let j = 0; j < payload.experience[i].experience_files.length; j++) {
                    formData.append('experience_files', payload.experience[i].experience_files[j]);
                }
                formData.append('email', payload.email);
                const responseExperience = yield call(
                    apiCall,
                    USER_MENTOR_EXPERIENCE,
                    formData,
                    null,
                    'POST',
                    { 'Content-Type': 'application/x-www-form-urlencoded' }
                );
                //console.log(responseExperience)
            }

            //education
            for (let i = 0; i < payload.education.length; i++) {
                const formData = new FormData();
                formData.append('academic_title', payload.education[i].academic_title);
                formData.append('study_center', payload.education[i].study_center);
                formData.append('city', payload.education[i].city);
                formData.append('start_date', payload.education[i].start_date);
                formData.append('graduation_date', payload.education[i].graduation_date);

                for (let j = 0; j < payload.education[i].education_files.length; j++) {
                    formData.append('education_files', payload.education[i].education_files[j]);
                }
                formData.append('email', payload.email);
                const responseEducation = yield call(
                    apiCall,
                    USER_MENTOR_EDUCATION,
                    formData,
                    null,
                    'POST',
                    { 'Content-Type': 'application/x-www-form-urlencoded' }
                );
                ///console.log(responseEducation)
            }
        } else {
            data = yield call(authCall, USERLIST, payload, 'POST');
        }
        yield put(isRequestOkAction(true));
        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: data.data.message,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(isRequestOkAction(false));
        yield put(isLoadingAction(false));
        const errorResponse = error.response;
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: errorResponse.data.message,
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* registerUser() {
    yield takeLatest(REGISTER_START, register);
}
