import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import GridItem from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const MegabytesToBytes = (x) => {
    x = x * 1024 * 1024;
    return x;
};

const DropZone = (props) => {
    const { t } = useTranslation();
    const { acceptedFiles, sizeFile, title, xs, value, setCustomObjectData } = props;
    const classes = useStyles();

    return (
        <GridItem item xs={xs}>
            <Typography variant='body1' align='left' className={classes.title}>
                {title}
            </Typography>
            <DropzoneArea
                onChange={(files) => setCustomObjectData(files)}
                acceptedFiles={acceptedFiles}
                dropzoneText={t('label22')}
                showPreviews={false}
                showPreviewsInDropzone={true}
                useChipsForPreview
                maxFileSize={MegabytesToBytes(sizeFile)}
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewChipProps={{
                    root: { marginTop: 20 },
                    color: 'secondary',
                    variant: 'default',
                    className: `${classes.chips}`,
                }}
            />
        </GridItem>
    );
};
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: '500',
    },
    chips: {
        marginTop: 20,
    },
}));

DropZone.propTypes = {
    acceptedFiles: PropTypes.array,
    sizeFile: PropTypes.number,
    title: PropTypes.string,
    xs: PropTypes.string,
};
DropZone.defaultProps = {
    acceptedFiles: [],
    sizeFile: 5,
    title: '',
    xs: '12',
};

export default DropZone;
