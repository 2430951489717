import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomRating from '@material-ui/lab/Rating';
import GridItem from '@material-ui/core/Grid';

const Rating = (props) => {
    const classes = useStyles();
    const { lg, md, sm, xs, rating, className } = props;
    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs} className={className}>
            <CustomRating className={classes.rating} value={rating} readOnly />
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    rating: {
        marginBottom: 20,
    },
}));

Rating.propTypes = {
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
    rating: PropTypes.number,
    className: PropTypes.object,
};
Rating.defaultProps = {
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
    rating: 0,
    className: [],
};

export default Rating;
