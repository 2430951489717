import { makeStyles, Grid, Typography, Button, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    familyContainer: {
        maxWidth: '1200px',
        margin: '100px auto',

        [theme.breakpoints.down('sm')]: {
            margin: '50px auto',
        },
    },
    familyImg: {
        maxWidth: 400,
        height: 400,
        position: 'relative',
        top: '-150px',
        zIndex: '2',

        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            top: 0,
            width: '90%',
            height: 'auto',
            margin: 'auto',
        },
    },
    familyContent: {
        boxSizing: 'border-box',
        padding: '15px',
    },
    familyAction: {
        textAlign: 'right',
    },
    familyButton: {
        width: '230px',
        fontSize: '19px',
        marginTop: theme.spacing(5),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
}));

export const AboutFamily = () => {
    const classes = useStyles();

    const content = [
        ` Get to know their experience as a Mixoloworld crew member by their
        badge, which represents the ranking as mixologists.`,
        `Learn by watching the finest people in the bartender community do their
        magic, and try it yourself at home. Don not forgot to tag us se we can
        see your tasty creations!`,
        ` Our commmunity grows bigger every time someone is encouraged to recreate
        one of our innovative recipes. Grow with us, and get the mix going.`,
    ];

    return (
        <div className={classes.familyContainer}>
            <Grid container>
                <Grid item sm={6}>
                    <div className={classes.familyImg}>
                        <img src='/img/juice.jpg' alt='mixologist family' />
                    </div>
                </Grid>
                <Grid item sm={6}>
                    <div className={classes.familyContent}>
                        {content.map((text) => (
                            <Typography
                                className={classes.marginTop}
                                align='justify'
                                variant='subtitle1'
                                gutterBottom
                            >
                                {text}
                            </Typography>
                        ))}

                        <div className={classes.familyAction}>
                            <Button
                                className={classes.familyButton}
                                variant='contained'
                                color='primary'
                            >
                                Join The Family
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
