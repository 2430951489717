import { put, call, takeLatest } from 'redux-saga/effects';
import { RECOVERY_PASSWORD_START, RECOVERY_PASSWORD_COMPLETE } from '../actionType';
import { authCall } from '../../utils/apiCall';
import { RECOVERY_PASS } from '../../utils/urls';
import { isLoadingAction, snackbarAction, isRequestOkAction } from '../actions/utilityActions';

export function* sendRecovery({ payload }) {
    try {
        const { data } = yield call(authCall, RECOVERY_PASS, payload, 'POST');
        yield put(isRequestOkAction(true));
        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: data.message,
            })
        );
        yield put({ type: RECOVERY_PASSWORD_COMPLETE, payload: data });
    } catch (error) {
        yield put(isLoadingAction(false));
        const errorResponse = error.response;
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: errorResponse.data.message,
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* sendRecoveryPassword() {
    yield takeLatest(RECOVERY_PASSWORD_START, sendRecovery);
}
