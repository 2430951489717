import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    MonthView,
    Toolbar,
    DateNavigator,
    Appointments,
    TodayButton,
    AppointmentTooltip,
    AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';

const Divider = (props) => {
    const classes = useStyles();
    const { data, appointmentMetaChange } = props;
    const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment {...restProps} className={classes.appointments}>
            {children}
        </Appointments.Appointment>
    );
    return (
        <div className={classes.root}>
            <Scheduler data={data}>
                <ViewState defaultCurrentDate='2021-08-16' />
                <MonthView />
                <Toolbar />
                <DateNavigator />
                <TodayButton />
                <Appointments appointmentComponent={Appointment} />

                <AppointmentTooltip
                    onAppointmentMetaChange={(a) => appointmentMetaChange(a.data)}
                    visible={false}
                />
            </Scheduler>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
    },
    appointments: {
        background: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
        justifyContent: 'center',
        textAlign: 'center',
        height: 'auto',
    },
}));

Divider.propTypes = {
    data: PropTypes.object,
};
Divider.defaultProps = {
    data: [],
    appointmentMetaChange: {},
};

export default Divider;
