import { put, call, takeLatest } from 'redux-saga/effects';
import { FORGOT_PASSWORD_START, FORFOT_PASSWORD_COMPLETE } from '../actionType/';
import { authCall } from '../../utils/apiCall';
import { FORGOT_PASS } from '../../utils/urls';
import { isLoadingAction, snackbarAction, isSuccessFullAction } from '../actions/utilityActions';

export function* sendToken({ payload }) {
    try {
        const { data } = yield call(authCall, FORGOT_PASS, payload, 'POST');
        yield put(isSuccessFullAction(true));
        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: data.message,
            })
        );
        yield put({ type: FORFOT_PASSWORD_COMPLETE, payload: data });
    } catch (error) {
        yield put(isLoadingAction(false));
        const errorResponse = error.response;
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: errorResponse.data.message,
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* sendTokenForgotPassword() {
    yield takeLatest(FORGOT_PASSWORD_START, sendToken);
}
