import React from 'react';
import PropTypes from 'prop-types';
import CustomCarousel from 'react-multi-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import 'react-multi-carousel/lib/styles.css';
import Theme from '../../utils/theme';

const Carousel = (props) => {
    const { children, itemsDesktop, itemsTablet, itemsMobile, arrows, dots } = props;
    const classes = useStyles();
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: itemsDesktop,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0,
            },
            items: itemsMobile,
            partialVisibilityGutter: 30,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464,
            },
            items: itemsTablet,
            partialVisibilityGutter: 30,
        },
    };

    const CustomDot = ({ onClick, ...rest }) => {
        const { active } = rest;

        return (
            <Button
                className={active ? 'active' : 'inactive'}
                onClick={() => onClick()}
                className={classes.dot}
            >
                <FiberManualRecordIcon
                    fontSize='small'
                    style={{ color: active ? Theme.palette.primary.main : Theme.palette.gray }}
                />
            </Button>
        );
    };

    return (
        <CustomCarousel
            additionalTransfrom={0}
            arrows={arrows}
            showDots={dots}
            autoPlaySpeed={3000}
            centerMode={false}
            containerClass='container-with-dots'
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={true}
            renderDotsOutside={false}
            responsive={responsive}
            sliderClass=''
            slidesToSlide={1}
            swipeable
            customDot={<CustomDot />}
            containerClass={classes.container}
        >
            {children}
        </CustomCarousel>
    );
};

const useStyles = makeStyles((props) => ({
    root: {
        marginTop: 90,
    },
    container: {
        paddingBottom: 20,
    },
    dot: {
        marginLeft: 10,
        borderRadius: 100,
        width: 0,
        minWidth: 0,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 2,
        paddingBottom: 2,
    },
}));

Carousel.propTypes = {
    itemsDesktop: PropTypes.number,
    itemsTablet: PropTypes.number,
    itemsMobile: PropTypes.number,
    arrows: PropTypes.bool,
    dots: PropTypes.bool,
};
Carousel.defaultProps = {
    itemsDesktop: 5,
    itemsTablet: 2,
    itemsMobile: 1,
    arrows: false,
    dots: true,
};

export default Carousel;
