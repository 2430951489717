import { makeStyles } from '@material-ui/core';
import { AboutFamily } from './components/AboutFamily/AboutFamily';
import { BackgroundHeroImg } from './components/BackgroundHeroImg/BackgroundHeroImg';
import { FamilyMember } from './components/FamilyMember/FamilyMember';
import { Ranking } from './components/Ranking/Ranking';

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {},
}));

export const View = () => {
    const classes = useStyles();

    const backgroundHereImages = [
        '/img/imageHome1.png',
        '/img/juice.jpg',
        '/img/backgroundPartners.png',
        '/img/showJuice.jpg',
    ];

    const rankBadges = ['/img/r1.png', '/img/r2.png', '/img/r3.png', '/img/r4.png', '/img/r5.png'];

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <BackgroundHeroImg images={backgroundHereImages} />

                <AboutFamily />

                <FamilyMember />

                <Ranking rankBadges={rankBadges} />
            </div>
        </div>
    );
};
