import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomLink from '@material-ui/core/Link';
import GridItem from '@material-ui/core/Grid';

const Link = (props) => {
    const classes = useStyles();

    const { value, onClick, color, variant, href, lg, md, sm, xs } = props;
    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs} className={classes.containerLink}>
            <CustomLink
                onClick={onClick}
                href={href}
                color={color}
                variant={variant}
                className={classes.link}
            >
                {value}
            </CustomLink>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    containerLink: {
        textAlign: 'center',
    },
    link: {
        fontWeight: '500',
    },
}));

Link.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    variant: PropTypes.string,
    component: PropTypes.string,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
Link.defaultProps = {
    value: '...',
    color: 'secondary',
    variant: 'body1',
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
};

export default Link;
