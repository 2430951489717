import {
    IS_LOADING,
    IS_REQUEST_OK,
    IS_SUCCESSFULL_REQUEST,
    IS_MODAL_CREATE_USER,
    IS_MODAL_EDIT_USER,
    SNACBAR,
} from '../actionType';

export const snackbarAction = (snackBar) => ({
    type: SNACBAR,
    snackBar,
});

export const isLoadingAction = (isLoading) => ({
    type: IS_LOADING,
    isLoading,
});

export const isModalCreateUserAction = (isModal) => ({
    type: IS_MODAL_CREATE_USER,
    isModal,
});

export const isModalEditUserAction = (isModal) => ({
    type: IS_MODAL_EDIT_USER,
    isModal,
});

export const isSuccessFullAction = (is_success) => ({
    type: IS_SUCCESSFULL_REQUEST,
    is_success,
});

export const isRequestOkAction = (isRequestOk) => ({
    type: IS_REQUEST_OK,
    isRequestOk,
});
