import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomAvatar from '@material-ui/core/Avatar';

const Avatar = (props) => {
    const classes = useStyles();

    const { width, height, src } = props;
    return (
        <CustomAvatar
            src={src}
            className={classes.avatar}
            style={{
                width: width,
                height: height,
            }}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: 'auto',
        marginTop: 20,
        marginBottom: 20,
    },
}));

Avatar.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
};
Avatar.defaultProps = {
    width: 120,
    height: 120,
    src: '/img/noPhoto.png',
};

export default Avatar;
