import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CustomButton from '@material-ui/core/Button';
import Theme from '../../utils/theme';

const CardCheck = (props) => {
    const { value, textValue, selected, className, onClick } = props;
    const classes = useStyles();

    let startIcon = <RadioButtonCheckedIcon className={classes.iconCheck} />;
    if (value !== selected)
        startIcon = <RadioButtonUncheckedIcon className={classes.iconUnchecked} />;
    return (
        <CustomButton
            onClick={onClick.bind(null, value)}
            variant={value !== selected ? 'outlined' : 'contained'}
            startIcon={startIcon}
            color={value !== selected ? 'secondary' : ''}
            className={value !== selected ? classes.unchecked : classes.check}
            style={{ marginBottom: 10, padding: 10 }}
            fullWidth
        >
            {textValue}
            <div className={classes.containerIconCard}>
                <img className={classes.iconCard} src='/img/icon_mastercard.png' />
            </div>
        </CustomButton>
    );
};

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: 10,
    },
    check: {
        backgroundColor: theme.palette.grayLight,
        color: theme.palette.primary.main,
        justifyContent: 'left',
    },
    unchecked: {
        color: theme.palette.secondary.main,
        justifyContent: 'left',
    },
    iconCheck: {
        color: theme.palette.secondary.main,
    },
    iconUnchecked: {
        color: theme.palette.secondary.main,
    },
    containerIconCard: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    iconCard: {
        width: 20,
    },
}));

CardCheck.propTypes = {
    value: PropTypes.string,
    textValue: PropTypes.string,
    selected: PropTypes.bool,
    className: PropTypes.object,
    onClick: PropTypes.func,
};
CardCheck.defaultProps = {
    value: 'none',
    textValue: 'none',
    selected: false,
    className: [],
    onClick: () => console.log('onChange'),
};

export default CardCheck;
