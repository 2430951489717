import { makeStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import RankLogo from '../../../../assets/rank.png';

/** Styling Component */
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1200px',
        margin: '200px auto 100px',

        [theme.breakpoints.down('sm')]: {
            margin: '100px 15px',
            boxSizing: 'border-box',
        },
    },
    rankingTitle: {
        textAlign: 'left',
        textDecoration: 'underline',
        textTransform: 'uppercase',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    rankImage: {
        width: '100%',
    },
    rankingBadge: {
        marginTop: theme.spacing(4),
    },
}));

export const Ranking = ({ rankBadges }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.rankingTitle} variant='h2' gutterBottom>
                Rankings
            </Typography>

            <Grid
                container
                spacing={1}
                justifyContent='space-between'
                className={classes.rankingBadge}
            >
                {rankBadges.map((item) => (
                    <Grid item sm={2}>
                        <img className={classes.rankImage} src={item} alt='LOGO' />
                        <Typography variant='subtitle1' align='justify'>
                            Lorem ipsum dolor sit amet consecter adipisicing elit. Maxime
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

Ranking.propTypes = {
    rankBadges: PropTypes.array.isRequired,
};
