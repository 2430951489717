/** Utility  */
export const SNACBAR = 'SNACKBAR';
export const IS_LOADING = 'IS_LOADING';
export const IS_MODAL_CREATE_USER = 'IS_MODAL_CREATE_USER';
export const IS_MODAL_EDIT_USER = 'IS_MODAL_EDIT_USER';
export const IS_FORM_OF = 'IS_FORM_OF';
export const IS_SUCCESSFULL_REQUEST = 'IS_SUCCESSFULL_REQUEST';
export const IS_REQUEST_OK = 'IS_REQUEST_OK';
/** Login */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const EMAIL = 'EMAIL';

/** Logout  */
export const LOGOUT_START = 'LOG_OUT';
export const LOGOUT_COMPLETE = 'LOG_OUT';

/** User Info  */
export const USER_INFO_START = 'USER_INFO_START';
export const USER_INFO_COMPLETE = 'USER_INFO_COMPLETE';

/** Sign In  */
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_COMPLETE = 'REGISTER_COMPLETE';

/** Send Password Token */
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORFOT_PASSWORD_COMPLETE = 'FORFOT_PASSWORD_COMPLETE';

/** Recovery Password */
export const RECOVERY_PASSWORD_START = 'RECOVERY_PASSWORD_START';
export const RECOVERY_PASSWORD_COMPLETE = 'RECOVERY_PASSWORD_COMPLETE';

/** Mentors */
export const LIST_MENTORS_START = 'LIST_MENTORS_START';
export const LIST_MENTORS_COMPLETE = 'LIST_MENTORS_COMPLETE';
export const ID_FILES_MENTORS_START = 'ID_FILES_MENTORS_START';
export const ID_FILES_MENTORS_COMPLETE = 'ID_FILES_MENTORS_COMPLETE';
