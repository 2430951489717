import { Helmet } from 'react-helmet';
import View from './view';

const Index = () => {
    const recipes = [
        {
            name: 'Recipe 1',
            img: '/img/topic1.png',
        },
        {
            name: 'Recipe 2',
            img: '/img/topic2.png',
        },
        {
            name: 'Recipe 3',
            img: '/img/topic3.png',
        },
        {
            name: 'Recipe 4',
            img: '/img/topic4.png',
        },
        {
            name: 'Recipe 5',
            img: '/img/topic2.png',
        },
    ];

    const gallery = [
        {
            url: '/img/logo_partners.png',
        },
        {
            url: '/img/logo_partners.png',
        },
        {
            url: '/img/logo_partners.png',
        },
        {
            url: '/img/logo_partners.png',
        },
    ];

    return (
        <>
            <Helmet title='Partners | Mixoloworld' />
            <View recipes={recipes} gallery={gallery} />
        </>
    );
};

export default Index;
