import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Paper from '@material-ui/core/Paper';

const TestimonialsCard = (props) => {
    const { description, rating, elevation } = props;
    const classes = useStyles();
    return (
        <Paper className={classes.root} elevation={elevation} square>
            <div className={classes.container}>
                <Typography variant='body1' className={classes.description}>
                    {description}
                </Typography>
                <Rating name='half-rating-read' defaultValue={rating} size='large' readOnly />
            </div>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'transparent',
    },
    container: {
        width: '100%',
        justifyContent: 'center',
    },
    description: {
        fontWeight: '300',
        width: '65%',
        margin: 'auto',
        marginTop: 20,
        marginBottom: 15,
        color: theme.palette.white,
    },
}));

TestimonialsCard.propTypes = {
    description: PropTypes.string.isRequired,
    elevation: PropTypes.number,
};
TestimonialsCard.defaultProps = {
    description: '',
    elevation: 0,
};

export default TestimonialsCard;
