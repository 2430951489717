import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const VideoPlayer = (props) => {
    const { src } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <video controls className={classes.video}>
                <source src={src} type='video/mp4' />
            </video>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            width: 500,
        },
        [theme.breakpoints.up('md')]: {
            width: 500,
        },
    },
    video: {
        width: '100%',
    },
}));

VideoPlayer.propTypes = {
    src: PropTypes.string,
};
VideoPlayer.defaultProps = {
    src: '',
};

export default VideoPlayer;
