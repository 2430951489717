import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CustomModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';

const Modal = (props) => {
    const {
        children,
        open,
        close,
        onClick,
        title,
        buttonDescription,
        classNameContainer,
        hideButtonsModal,
    } = props;
    const classes = useStyles();

    return (
        <CustomModal
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: {
                    backdropFilter: 'blur(7px)',
                },
            }}
        >
            <Fade in={open}>
                <Paper className={[classes.paper, classNameContainer]}>
                    <div className={classes.containerButtonClose}>
                        <Typography variant='h6' align='left' className={classes.title}>
                            {title}
                        </Typography>
                        <IconButton onClick={close} className={classes.buttonClose}>
                            <CancelIcon className={classes.iconButtonClose} />
                        </IconButton>
                    </div>
                    {children}
                    {hideButtonsModal ? null : (
                        <div className={classes.containerButtons}>
                            <Button
                                color='secondary'
                                variant='contained'
                                className={classes.buttons}
                                onClick={onClick}
                            >
                                {buttonDescription}
                            </Button>
                        </div>
                    )}
                </Paper>
            </Fade>
        </CustomModal>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '80%',
        '@media (min-width:600px)': {
            width: '70%',
        },
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
        padding: 20,
    },
    containerButtons: {
        textAlign: 'center',
        paddingTop: 20,
    },
    containerButtonClose: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'right',
        paddingBottom: 5,
    },
    title: {
        flex: 1,
        alignSelf: 'center',
    },
    buttonClose: {
        color: theme.palette.secondary.main,
        flex: 0,
    },
    iconButtonClose: {
        transform: 'scale(1.4)',
    },
    buttons: {
        margin: 5,
    },
}));

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.string,
    buttonDescription: PropTypes.string,
    hideButtonsModal: PropTypes.bool,
};
Modal.defaultProps = {
    open: false,
    close: true,
    title: '',
    buttonDescription: '',
    hideButtonsModal: false,
};

export default Modal;
