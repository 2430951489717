import { put, call, takeLatest } from 'redux-saga/effects';
import {
    LOGIN_START,
    LOGIN_COMPLETE,
    LOGOUT_START,
    LOGOUT_COMPLETE,
    USER_INFO_START,
} from '../actionType/';
import { authCall } from '../../utils/apiCall';
import { AUTH } from '../../utils/urls';
import { isLoadingAction, snackbarAction } from '../actions/utilityActions';
import {
    setLocalStorage,
    GET_TOKEN,
    REFRESH_TOKEN,
    GET_EMAIL,
    GET_FIRST_NAME,
    GET_LAST_NAME,
    GET_TYPE,
    GET_ID,
    GET_IMG_PROFILE,
    rmLocalStorage,
} from '../../utils/localStorage';

export function* logIn({ payload }) {
    try {
        const { data } = yield call(authCall, AUTH, payload, 'POST');
        yield put({ type: LOGIN_COMPLETE, payload: data });
        yield put({ type: USER_INFO_START, payload: null });
        yield setLocalStorage(data.access_token, GET_TOKEN);
        yield setLocalStorage(data.refresh_token, REFRESH_TOKEN);
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: errorResponse.data.description || 'Internal Message',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* logOut() {
    yield rmLocalStorage(GET_TOKEN);
    yield rmLocalStorage(REFRESH_TOKEN);
    yield rmLocalStorage(GET_EMAIL);
    yield rmLocalStorage(GET_FIRST_NAME);
    yield rmLocalStorage(GET_LAST_NAME);
    yield rmLocalStorage(GET_TYPE);
    yield rmLocalStorage(GET_ID);
    yield rmLocalStorage(GET_IMG_PROFILE);
    put({ type: LOGOUT_COMPLETE });
}

export default function* authUser() {
    yield takeLatest(LOGIN_START, logIn);
    yield takeLatest(LOGOUT_START, logOut);
}
