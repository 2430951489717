import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import GridItem from '@material-ui/core/Grid';

const TextInput = (props) => {
    const {
        key,
        error,
        placeholder,
        inputRef,
        multiline,
        type,
        rows,
        helperText,
        lg,
        md,
        sm,
        xs,
        setCustomObjectData,
        ...selectChip
    } = props;
    const handleOnChange = (e) => {
        if (setCustomObjectData) {
            setCustomObjectData((data) => ({
                ...data,
                [e.target.name]: e.target.value,
            }));
        }
    };

    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <TextField
                {...selectChip}
                key={key}
                id='outlined-error-helper-text'
                type={type}
                error={error}
                inputRef={inputRef}
                label={placeholder}
                variant='outlined'
                size='small'
                fullWidth
                multiline={multiline}
                rows={rows}
                onChange={handleOnChange}
                helperText={helperText}
            />
        </GridItem>
    );
};

TextInput.propTypes = {
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    rows: PropTypes.number,
    helperText: PropTypes.string,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
    setCustomObjectData: PropTypes.func,
};
TextInput.defaultProps = {
    placeholder: '',
    multiline: false,
    type: 'text',
    rows: 3,
    helperText: '',
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
    setCustomObjectData: null,
};

export default TextInput;
