import { combineReducers } from 'redux';

import { utilityReducer } from './utilityReducer';
import { loginReducer } from './loginReducer';
import { userReducer } from './userReducer';
import { listMentorsReducer, idFilesMentorsReducer } from './mentorsReducer';

export default combineReducers({
    utilityReducer,
    loginReducer,
    userReducer,
    listMentorsReducer,
    idFilesMentorsReducer,
});
