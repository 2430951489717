import { get } from 'lodash';
import {
    SNACBAR,
    IS_REQUEST_OK,
    IS_LOADING,
    IS_MODAL_CREATE_USER,
    IS_MODAL_EDIT_USER,
    IS_SUCCESSFULL_REQUEST,
} from '../actionType';

const initialState = {
    isLoading: false,
    isModalCreateUser: false,
    isModalEditUser: false,
    is_success: false,
    snackBar: {
        open: false,
        type: '',
        message: '',
    },
    isRequestOk: false,
};

export const utilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SNACBAR:
            return {
                ...state,
                snackBar: get(action, 'snackBar'),
            };

        case IS_LOADING:
            return { ...state, isLoading: get(action, 'isLoading') };
        case IS_REQUEST_OK:
            return { ...state, isRequestOk: get(action, 'isRequestOk') };
        case IS_SUCCESSFULL_REQUEST:
            return { ...state, is_success: get(action, 'is_success') };
        case IS_MODAL_CREATE_USER:
            return { ...state, isModalCreateUser: get(action, 'isModal') };
        case IS_MODAL_EDIT_USER:
            return { ...state, isModalEditUser: get(action, 'isModal') };

        default:
            return state;
    }
};
