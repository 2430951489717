import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import Theme from '../../utils/theme';
import { Carousel, ImageCard } from '../../components';

const View = (props) => {
    const classes = useStyles();
    const { recipes, gallery } = props;

    return (
        <div className={classes.root}>
            <div className={classes.banner}>
                <div className={classes.content}>
                    <Typography variant='h1' className={clsx(classes.title, classes.heroTitle)}>
                        MIX YOUR PASSION <br />
                        WITH OUR WORLD
                    </Typography>
                    <div className={classes.HeroCallToAction}>
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            className={classes.buttonBanner}
                        >
                            MEET OUR MIXOLOGISTS
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <div className={clsx(classes.content, classes.aboutUs)}>
                    <div className={classes.aboutImage}>
                        <img src='/img/imageHome1.png' alt='Logo' style={{ width: '80%' }} />
                    </div>
                    <div className={classes.aboutTile}>
                        <Typography variant='h2' color='primary'>
                            ABOUT US
                        </Typography>
                        <Typography
                            variant='body1'
                            gutterBottom
                            className={classes.aboutDescription}
                        >
                            Few things can be compared to the feeling of being passionate about
                            something, and being able to share it with the world is a privilege. You
                            can now be a part of the ultimate mixology experience and get immersed
                            in this whole new world!
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.section2}>
                <div className={clsx(classes.content, classes.recipesSection)}>
                    <Typography variant='h2' color='primary' className={classes.title}>
                        RECIPES
                    </Typography>
                    <Box className={classes.marginTop}>
                        <Carousel>
                            {recipes.map((item, i) => (
                                <ImageCard key={i} title={item.name} img={item.img} />
                            ))}
                        </Carousel>
                    </Box>
                </div>
            </div>
            <div className={classes.section3}>
                <div className={classes.content}>
                    <Typography variant='h2' color='primary' className={classes.title}>
                        GALLERY
                    </Typography>
                    <Box className={classes.marginTop}>
                        <Carousel>
                            {gallery.map((item, i) => (
                                <ImageCard
                                    key={i}
                                    img={item.url ? `${item.url}` : '/img/noPhoto.png'}
                                />
                            ))}
                        </Carousel>
                    </Box>
                </div>
            </div>
            <div className={classes.section1}>
                <div className={clsx(classes.content, classes.contactUs)}>
                    <Typography variant='body1' gutterBottom>
                        Curious about Mixoloworld? Would you like to be part of our crew? Are you a
                        brand who would like to partner-up?
                    </Typography>
                    <Button variant='contained' color='primary' className={classes.buttonContact}>
                        Contact Us
                    </Button>
                </div>
            </div>
        </div>
    );
};

View.propTypes = {
    recipes: PropTypes.array.isRequired,
    gallery: PropTypes.array.isRequired,
    testimonials: PropTypes.object,
    t: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    banner: {
        display: 'flex',
        backgroundImage: `url("/img/backgroundHome.png")`,
        paddingTop: 100,
        paddingBottom: 100,

        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
    },
    section2: {
        backgroundColor: theme.palette.lightestGray,
    },
    section4: {
        backgroundImage: `url("/img/backgroundTestimonials.png")`,
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
        marginLeft: 200,
        marginRight: 200,
        paddingTop: 60,
        paddingBottom: 60,
        width: 'fill-available',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: '40px auto',
        },
    },
    heroTitle: {
        color: Theme.palette.white,
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '4rem',
        },
    },
    HeroCallToAction: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            textAlign: 'center',
        },
    },
    subtitle: {
        fontWeight: '300',
        width: '65%',
        margin: 'auto',
        marginTop: 15,
        marginBottom: 20,
    },
    buttonBanner: {
        margin: 10,
        fontWeight: '400',
        fontSize: 25,
        border: '1px solid ' + theme.palette.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            margin: '10px auto',
        },
    },
    aboutUs: {
        flexDirection: 'row',
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            margin: '50px 15px',
        },
    },
    aboutImage: {
        flex: 1,
        marginTop: -200,

        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
        },
    },
    aboutTile: {
        flex: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    aboutDescription: {
        marginTop: 20,
    },
    recipesSection: {
        [theme.breakpoints.down('sm')]: {
            margin: '40px auto',
        },
    },
    marginTop: {
        margin: '20px auto',
    },
    buttonContact: {
        margin: 10,
        fontWeight: '400',
    },
    title: {
        fontWeight: 'bold',
    },
    contactUs: {
        [theme.breakpoints.down('sm')]: {
            margin: '30px 20px',
        },
    },
}));

export default View;
