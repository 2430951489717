import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomButton from '@material-ui/core/Button';
import GridItem from '@material-ui/core/Grid';
import LoopIcon from '@material-ui/icons/Loop';

const Button = (props) => {
    const {
        style,
        value,
        onClick,
        color,
        variant,
        fullWidth,
        disabled,
        className,
        isLoading,
        startIcon,
        lg,
        md,
        sm,
        xs,
    } = props;
    const classes = useStyles();
    return (
        <GridItem item lg={lg} md={md} sm={sm} xs={xs}>
            <CustomButton
                style={style}
                onClick={onClick}
                color={color}
                variant={variant}
                fullWidth={fullWidth}
                disabled={disabled}
                className={[classes.button, className]}
                startIcon={startIcon}
            >
                {isLoading ? <LoopIcon className={classes.icon} /> : value}
            </CustomButton>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: 10,
    },
    icon: {
        animation: '$rotateIcon 1s infinite linear',
    },
    '@keyframes rotateIcon': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(359deg)',
        },
    },
}));

Button.propTypes = {
    onClick: PropTypes.func,
    color: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.object,
    value: PropTypes.string,
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string,
};
Button.defaultProps = {
    color: 'secondary',
    variant: 'contained',
    fullWidth: false,
    disabled: false,
    className: [],
    value: '...',
    lg: '12',
    md: '12',
    sm: '12',
    xs: '12',
};

export default Button;
