import { useState, useEffect } from 'react';
import { Paper, makeStyles, Typography, Box, Grid } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1200px',
        margin: 'auto',
    },
    familyGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    card: {
        height: 500,
        width: 300,
        overflow: 'hidden',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',

        [theme.breakpoints.down('sm')]: {
            width: '90%',
            marginTop: theme.spacing(3),
        },
    },
    cardImage: {
        width: 180,
        height: 180,
        margin: 'auto',

        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            margin: 'auto',
            borderRadius: '50%',
            border: `8px solid ${theme.palette.black}`,
        },
    },
    cardContainer: {
        padding: theme.spacing(5),
    },
    cardContent: {
        marginTop: theme.spacing(3),
    },
    uppercase: {
        textTransform: 'uppercase',
    },
}));

export const FamilyMember = () => {
    const classes = useStyles();
    const [data, setData] = useState(null);

    const fetchDataFunction = async () => {
        const result = await axios.get('https://randomuser.me/api/?results=3');
        setData(result.data.results);
    };

    useEffect(() => {
        fetchDataFunction();
    }, []);

    console.log('DATA', data);

    return (
        <Box className={classes.root}>
            <div className={classes.familyGrid}>
                {data?.map((item) => (
                    <Paper className={classes.card}>
                        <div className={classes.cardContainer}>
                            <div className={classes.cardImage}>
                                <img src={item?.picture?.large} alt='Mixoloworld' />
                            </div>
                            <div className={classes.cardContent}>
                                <Typography className={classes.uppercase} variant='h6' gutterBottom>
                                    Juliana, 26
                                </Typography>
                                <Typography variant='subtitle1' gutterBottom>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                                    mollitia, molestiae quas vel sint commodi repudiandae
                                    consequuntur voluptatum laborum numquam blanditii
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                ))}
            </div>
        </Box>
    );
};
