import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '@material-ui/core/Grid';

const Grid = (props) => {
    const { children, className, justify, spacing } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <GridContainer
                container
                spacing={spacing}
                justify={justify}
                alignItems='flex-start'
                className={className}
            >
                {children}
            </GridContainer>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

Grid.propTypes = {
    spacing: PropTypes.number,
    justify: PropTypes.string,
};
Grid.defaultProps = {
    spacing: 2,
    justify: 'center',
};

export default Grid;
