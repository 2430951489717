import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Theme from '../../utils/theme';
import { Carousel, ImageCard } from '../../components';

const View = (props) => {
    const classes = useStyles();
    const { gallery } = props;

    return (
        <div className={classes.root}>
            <div className={classes.banner}>
                <div className={classes.content}>
                    <Typography variant='h2' color='primary' className={classes.partnerTitle}>
                        PARTNERS
                    </Typography>
                    <Typography variant='body1' gutterBottom style={{ color: Theme.palette.white }}>
                        Don’t forget to register and get the latest news and recipes for these
                        spirits. <br />
                        <br />
                        We believe in building a community that connects and synchronizes in a
                        perfect way. We extend the invitation to other brands to be a part of a
                        family where we can all grow and create something new and exciting.
                    </Typography>
                </div>
            </div>

            <div className={classes.partners}>
                <div className={classes.content}>
                    <Typography variant='h4' color='primary' className={classes.title}>
                        SOME OF OUR PARTNERS
                    </Typography>
                    <Carousel>
                        {gallery.map((item, i) => (
                            <ImageCard
                                key={i}
                                img={item.url ? `${item.url}` : '/img/noPhoto.png'}
                                overlay='transparent'
                            />
                        ))}
                    </Carousel>
                    <Button variant='contained' color='primary' className={classes.buttonContact}>
                        JOIN THE FAMILY
                    </Button>
                </div>
            </div>
        </div>
    );
};

View.propTypes = {
    recipes: PropTypes.array.isRequired,
    gallery: PropTypes.array.isRequired,
    testimonials: PropTypes.object,
    t: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    banner: {
        display: 'flex',
        backgroundImage: `url("/img/backgroundPartners.png")`,
        paddingTop: 100,
        paddingBottom: 100,
    },
    partnerTitle: {
        color: Theme.palette.white,

        [theme.breakpoints.down('sm')]: {
            marginBottom: 25,
        },
    },
    section2: {
        backgroundColor: theme.palette.lightestGray,
    },
    section4: {
        backgroundImage: `url("/img/backgroundTestimonials.png")`,
    },
    partners: {
        margin: '50px 0',
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
        marginLeft: 260,
        marginRight: 260,
        paddingTop: 60,
        paddingBottom: 60,
        width: 'fill-available',

        [theme.breakpoints.down('sm')]: {
            margin: '0 15px',
            padding: 0,
        },
    },

    subtitle: {
        fontWeight: '300',
        width: '65%',
        margin: 'auto',
        marginTop: 15,
        marginBottom: 20,
    },
    buttonBanner: {
        margin: 10,
        fontWeight: '400',
        fontSize: 25,
    },
    buttonContact: {
        margin: '50px auto 10px',
        fontWeight: '400',
    },
    title: {
        fontWeight: 'bold',

        [theme.breakpoints.down('sm')]: {
            margin: '20px auto',
        },
    },
}));

export default View;
