import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant='body1' className={classes.text}>
                {t('labelFooterHdTechs')}
            </Typography>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        borderTop: `1px solid ${theme.palette.lightGray} `,
        textAlign: 'center',
        padding: 25,
        color: 'white',
    },
    text: {
        color: theme.palette.white,
    },
}));
export default Footer;
