import Helmet from 'react-helmet';

import View from './view';

const Index = () => {
    const recipes = [
        {
            name: 'Recipe 1',
            img: '/img/recipe1.png',
        },
        {
            name: 'Recipe 2',
            img: '/img/recipe2.png',
        },
        {
            name: 'Recipe 3',
            img: '/img/recipe3.png',
        },
        {
            name: 'Recipe 4',
            img: '/img/recipe4.png',
        },
    ];

    const gallery = [
        {
            url: '/img/video1.png',
        },
        {
            url: '/img/video2.png',
        },
        {
            url: '/img/video3.png',
        },
    ];

    return (
        <>
            <Helmet title='Welcome to Mixoloworld' />
            <View recipes={recipes} gallery={gallery} />
        </>
    );
};

export default Index;
