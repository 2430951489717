import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Home, About, Partners, ContactUs, Mixologists, Recipes } from './pages';
import { Drawer } from './components';

const routes = () => {
    return (
        <div>
            <Drawer>
                <Switch></Switch>
            </Drawer>
            <Switch>
                <Route path='/' exact>
                    <Home />
                </Route>
                <Route path='/about' exact>
                    <About />
                </Route>
                <Route path='/partners' exact>
                    <Partners />
                </Route>
                <Route path='/contact-us' exact>
                    <ContactUs />
                </Route>
                <Route exact path='/mixologists' component={Mixologists} />
                <Route exact path='/recipes' component={Recipes} />
            </Switch>
        </div>
    );
};

export default routes;
