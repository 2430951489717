import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, IconButton, makeStyles } from '@material-ui/core';
import { CloudUpload, Delete } from '@material-ui/icons';
import GridItem from '@material-ui/core/Grid';

const ChooserAvatar = (props) => {
    const { xs, refAvatar, imageAvatar, handleOnChangeAvatar, handleOnClickAvatar } = props;
    const classes = useStyles();

    return (
        <GridItem item xs={xs}>
            <label htmlFor='avatar-image-upload' className={classes.root}>
                <div className={classes.containerAvatar}>
                    <Button
                        variant='text'
                        color='inherit'
                        component='span'
                        mb={2}
                        onClick={handleOnClickAvatar}
                        className={classes.touchable}
                    >
                        <Avatar
                            className={classes.avatar}
                            alt='Avatar'
                            src={imageAvatar || '/static/img/avatars/default-profile.svg'}
                        />

                        <input
                            ref={refAvatar}
                            accept='image/*'
                            hidden
                            id='avatar-image-upload'
                            type='file'
                            onChange={handleOnChangeAvatar}
                        />
                    </Button>
                    {imageAvatar ? (
                        <IconButton
                            color='secondary'
                            aria-label='add an alarm'
                            className={classes.icons}
                        >
                            <Delete />
                        </IconButton>
                    ) : (
                        <IconButton
                            color='secondary'
                            aria-label='add an alarm'
                            className={classes.icons}
                        >
                            <CloudUpload />
                        </IconButton>
                    )}
                </div>
            </label>
        </GridItem>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        marginTop: 20,
        marginBottom: 20,
    },
    containerAvatar: {
        transform: 'translateX(25px)',
        width: 'fit-content',
        margin: 'auto',
    },
    avatar: {
        width: 100,
        height: 100,
    },
    touchable: {
        borderRadius: '100%',
        padding: 0,
    },
    icons: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        pointerEvents: 'none',
        transform: 'scale(0.7) translateX(-100%) translateY(100%)',
    },
}));

ChooserAvatar.propTypes = {
    xs: PropTypes.string,
};
ChooserAvatar.defaultProps = {
    xs: '12',
};

export default ChooserAvatar;
