import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Footer, SnackbarAlert } from './components';
import Routes from './Routes';
import theme from './utils/theme';

const App = () => {
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <Routes />
                        <SnackbarAlert />
                        <Footer />
                    </Router>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </>
    );
};

export default App;
