import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backgroundHeroImages: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'no-wrap',
        width: '100%',
        height: '450px',
        position: 'relative',
        zIndex: '1',

        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: 'rgba(0,0,0,0.6)',
            opacity: 1,
        },

        '& img': {
            width: '33.22%',
            height: '100%',
            objectFit: 'cover',
            overflow: 'hidden',
        },
    },
    backgroundHeroContent: {
        position: 'absolute',
        color: theme.palette.white,
        zIndex: '2',
        textAlign: 'center',
    },
    backgroundHeroTitle: {
        textTransform: 'uppercase',
        borderBottom: `1px solid ${theme.palette.white}`,
        fontSize: '3rem',
        fontWeight: '900',
        display: 'inline',
    },
    backgroundHeroDescription: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: '18px',
        maxWidth: '630px',
        boxSizing: 'border-box',
    },
}));

export const BackgroundHeroImg = ({ images }) => {
    const classes = useStyles();

    return (
        <div className={classes.backgroundHeroImages}>
            {images.map((img) => (
                <img src={img} alt='Mixoloworld party' />
            ))}

            <div className={classes.backgroundHeroContent}>
                <Typography
                    variant='h1'
                    component='h1'
                    classes={{ root: classes.backgroundHeroTitle }}
                    gutterBottom
                >
                    Mixologists
                </Typography>
                <div className={classes.backgroundHeroDescription}>
                    <p>More than our DNA, our essence.</p>
                    <p>
                        Get to know our team, where they come from, and their specialty as artists.
                        Register and start your library of recipes!
                    </p>
                </div>
            </div>
        </div>
    );
};

BackgroundHeroImg.propTypes = {
    images: PropTypes.array.isRequired,
};
