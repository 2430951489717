import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Theme from '../../utils/theme';
import { TextInput } from '../../components';

const View = (props) => {
    const classes = useStyles();

    const inputFields = {
        First: { name: 'First Name' },
        Last: { name: 'Last Name' },
        Email: { name: 'Email Address' },
        Phone: { name: 'Contact Number' },
        Message: { name: 'Reason for Contact' },
    };

    return (
        <div className={classes.root}>
            <div className={classes.banner}>
                <div className={classes.content}>
                    <Typography variant='h2' color='primary' style={{ color: Theme.palette.white }}>
                        CONTACT US
                    </Typography>
                    <Typography variant='body1' gutterBottom style={{ color: Theme.palette.white }}>
                        Curious about Mixoloword? Would you like to be part of our crew? Are you a
                        brand who would like to partner-up? Contact us!
                    </Typography>
                </div>
            </div>

            <div className={classes.form}>
                <Grid container spacing={3}>
                    {Object.keys(inputFields).map((key) => (
                        <Grid item xs={12} sm={key === 'Message' ? 12 : 6} key={key}>
                            <TextInput
                                placeholder={inputFields[key].name}
                                multiline={key === 'Message' && true}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Button variant='contained' color='primary' className={classes.buttonContact}>
                    SEND MESSAGE
                </Button>
            </div>
        </div>
    );
};

View.propTypes = {
    recipes: PropTypes.array.isRequired,
    gallery: PropTypes.array.isRequired,
    testimonials: PropTypes.object,
    t: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    banner: {
        display: 'flex',
        backgroundImage: `url("/img/backgroundHome.png")`,
        paddingTop: 100,
        paddingBottom: 100,
    },
    section2: {
        backgroundColor: theme.palette.lightestGray,
    },
    section4: {
        backgroundImage: `url("/img/backgroundTestimonials.png")`,
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
        marginLeft: 260,
        marginRight: 260,
        paddingTop: 60,
        paddingBottom: 60,
        width: 'fill-available',

        [theme.breakpoints.down('sm')]: {
            margin: '0 15px',
            padding: 0,
        },
    },

    subtitle: {
        fontWeight: '300',
        width: '65%',
        margin: 'auto',
        marginTop: 15,
        marginBottom: 20,
    },
    buttonBanner: {
        margin: 10,
        fontWeight: '400',
        fontSize: 25,
    },
    buttonContact: {
        margin: '20px 0',
        fontWeight: '400',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '20px auto 10px',
        },
    },
    title: {
        fontWeight: 'bold',
    },
    form: {
        paddingLeft: '20%',
        paddingRight: '20%',
        paddingTop: 140,
        paddingBottom: 140,

        [theme.breakpoints.down('sm')]: {
            padding: '50px 15px',
        },
    },
}));

export default View;
